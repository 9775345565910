import React from "react"
import {graphql, Link} from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import Seo from "../components/App/Seo";
import Navbar from "../components/App/Navbar";
import MailchimpForm from "../components/App/MailchimpForm";

const ComponentName = ({data}) => {
    const {title, headlinePrefix, seo, image, blogContent, robotNoIndex} = data.blog


    return (
        <Layout>
            <Seo seotags={seo} noIndex={robotNoIndex}/>
            <Navbar/>
            <PageBanner
                pageTitle={title}
                homePageUrl="/"
                activePageText={title}
            />
            <div className="blog-detail-image">
                <img src={image.localFile.url} alt={image.alternativeText}/>
            </div>
            <div className={'blog'}>
                <section className="blog-details-area pb-100">
                    <div className="section-title">
                        <h6 className="sub-title">{headlinePrefix}</h6>
                        <h1>{title}</h1>
                    </div>


                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="blog-details-desc ">

                                    {blogContent?.map((dc, idx) => {
                                        const ContentText = dc.text
                                        const ContentImage = process.env.API_URL + dc?.image?.url
                                        const ContentImageAlt = dc?.image?.alternativeText
                                        const TextImageText = dc.textimgcontent
                                        const TextImageImage = process.env.API_URL + dc?.textimgimage?.url
                                        const TextImageImageAlt = dc?.textimgimage?.alternativeText
                                        const ContentForDownload = process.env.API_URL + dc?.downloadItem?.url
                                        const ImageForDownload = process.env.API_URL + dc?.imageForCoupon?.url
                                        const ImageForDownloadAlt = dc?.imageForCoupon?.alternativeText

                                        return (
                                            <div key={idx} className={'dc-block'}>
                                                <ReactMarkdown className={'dc-text-block'} children={ContentText}/>
                                                {
                                                    dc?.image?.url !== undefined &&
                                                    <img src={ContentImage} alt={ContentImageAlt}/>
                                                }
                                                {
                                                    dc?.textimgcontent !== undefined && <div
                                                        className={`textimg-block-wrapper ${dc.imgright ? 'bg-pink' : 'bg-violet'}`}>
                                                        <div
                                                            className={`textimg-wrapper ${dc.imgright ? 'cal-flex-reverse' : 'cal-flex'}`}>
                                                            <div className="img-wrapper col-lg-6">
                                                                {dc?.textimgimage?.url !== undefined &&
                                                                <img src={TextImageImage} alt={TextImageImageAlt}/>}
                                                            </div>
                                                            <div className={'text-block col-lg-6'}>
                                                                <div className={'text-wrapper'}>
                                                                    <ReactMarkdown>{TextImageText}</ReactMarkdown>
                                                                    {dc?.url !== null &&
                                                                    <Link to={dc?.url} target={'_blank'}
                                                                          className="default-btn">
                                                                        {dc?.linktext}
                                                                    </Link>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                }

                                                {
                                                    dc?.downloadItem !== undefined && <div>
                                                        <h2>{dc?.couponTitle}</h2>
                                                        <div>
                                                            <p>{dc?.couponDescription}</p>
                                                        </div>
                                                        <a href={ContentForDownload} target={'_blank'} download> <img
                                                            src={ImageForDownload} alt={ImageForDownloadAlt}/></a>
                                                    </div>
                                                }

                                                {
                                                    dc.marketing_campaign === true &&
                                                    <MailchimpForm dc={dc}/>
                                                }


                                            </div>
                                        )

                                    })}

                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            </div>
            <Footer/>
        </Layout>
    )


}


export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlog(slug: { eq: $slug }) {
    headlinePrefix
      title
        blogContent
       image{
        localFile {
             url
            }
          }
      seo {
          metadescription
          metatitle
        }
      robotNoIndex
    }
  }
`

export default ComponentName
